import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-ellipsis-loader',
    templateUrl: './ellipsis-loader.component.html',
    styleUrls: ['./ellipsis-loader.component.scss']
})
export class EllipsisLoaderComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {}
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {ContactUsService} from "../../service/contact-us.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {PhoneMaskService} from "../../service/phoneMask.service";

@Component({
    selector: 'app-popup-contact-us',
    templateUrl: './popup-contact-us.component.html',
    styleUrls: ['./popup-contact-us.component.scss']
})
export class PopupContactUsComponent implements OnInit {
    @Input() popupInfo: any = {show: false};
    @Output() close: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    phoneMask = this.phoneMaskService.setPhoneMask();
    constructor(private formBuilder: FormBuilder,
                private phoneMaskService: PhoneMaskService,
                private loaderService: LoaderService,
                private contactUsService: ContactUsService,
                private popupComponent: PopupComponent) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: [null, Validators.required],
            phone: [null, Validators.required],
            email: [null, Validators.required],
            message: [null, Validators.required]
        });
    }

    sendForm() {
        const name = this.form.controls.name.value;
        const phone = this.form.controls.phone.value;
        const email = this.form.controls.email.value;
        const message = this.form.controls.message.value;

        this.contactUsService.send(name, phone, email, message).subscribe(data => {
            this.setRequestResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setRequestResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup() {
        this.form.reset();
        this.close.emit();
    }
}

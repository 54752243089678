/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ellipsis-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ellipsis-loader.component";
var styles_EllipsisLoaderComponent = [i0.styles];
var RenderType_EllipsisLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EllipsisLoaderComponent, data: {} });
export { RenderType_EllipsisLoaderComponent as RenderType_EllipsisLoaderComponent };
export function View_EllipsisLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "lds-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_EllipsisLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ellipsis-loader", [], null, null, null, View_EllipsisLoaderComponent_0, RenderType_EllipsisLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.EllipsisLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EllipsisLoaderComponentNgFactory = i1.ɵccf("app-ellipsis-loader", i2.EllipsisLoaderComponent, View_EllipsisLoaderComponent_Host_0, {}, {}, []);
export { EllipsisLoaderComponentNgFactory as EllipsisLoaderComponentNgFactory };

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {CONTACT_US_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {
    private config = CONTACT_US_CONFIG;

    constructor(private http: HttpClient,
                public loaderService: LoaderService) {}

    send(name, phone, email, message) {
        this.loaderService.show();
        return this.http.post<any>(this.config.send, {name, phone, email, message});
    }
}

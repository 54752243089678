import { CONTACT_US_CONFIG } from "../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var ContactUsService = /** @class */ (function () {
    function ContactUsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = CONTACT_US_CONFIG;
    }
    ContactUsService.prototype.send = function (name, phone, email, message) {
        this.loaderService.show();
        return this.http.post(this.config.send, { name: name, phone: phone, email: email, message: message });
    };
    ContactUsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactUsService_Factory() { return new ContactUsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ContactUsService, providedIn: "root" });
    return ContactUsService;
}());
export { ContactUsService };

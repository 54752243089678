import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {GAMES_PROVIDER_CONFIG} from "../../config/api";
import {UsersPointService} from "../users/users-point.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {GAME_PROVIDER_ERRORS_CODE} from "./gameProviderErrorsCode.config";
import {PopupComponent} from "../../shared/popup/popup.component";


@Injectable({
    providedIn: 'root'
})
export class GamesProviderService extends SettingsLoaderService{
    private config = GAMES_PROVIDER_CONFIG;

    constructor(private popupComponent: PopupComponent,
                private http: HttpClient,
                public loaderService: LoaderService,
                private auth: AuthenticationService,
                private usersPointService: UsersPointService,) {
        super(loaderService);
    }

    chooseGame(groupId, gameProviderId) {
        this.showLoader();
        let post = this.http.post<any>(this.config.chooseGame, {groupId, gameProviderId});
        return post;
    }

    getGamesList(pointId) {
        this.showLoader();
        let post = this.http.post<any>(this.config.gamesList, {pointId});
        return post;
    }

    connectRiver() {
        this.showLoader();
        let post = this.http.post<any>(this.config.connectRiver, {});
        return post;
    }

    setProviders(games) {
        sessionStorage.setItem('gamesProviders', JSON.stringify(games));
    }

    getProviders() {
        return JSON.parse(sessionStorage.getItem('gamesProviders'));
    }

    getActiveProvider(providers?) {
        const providersList = providers ? providers : this.getProviders();
        return providersList.find(el => el.short_name === this.usersPointService.getGameVendor());
    }

    getActiveProviderName(): string {
        const provider = this.getActiveProvider();
        return provider ? provider.name : '';
    }

    getActiveProviderShortName() {
        const provider = this.getActiveProvider();
        return provider ? provider.short_name : '';
    }

    onGameChosen(game, callback) {
        const gameId = game.id;
        this.chooseGame(this.usersPointService.getGroupId(), gameId).subscribe(data => {
            callback(data, gameId);

            if (data.status === true) {
                if (data.code && data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                    console.log(data.code);
                } else {
                    this.loginUser(data.userPointId, data.pointPlayersEarnIncome, data.gameVendorShortName);
                }
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }


        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            // this.loaderService.hide();
        });
    }

    loginUser(pointId, pointPlayersEarnIncome, gameVendorShortName) {
        this.usersPointService.setUserPointId(pointId);
        this.usersPointService.setPointPlayersEarnIncome(pointPlayersEarnIncome);
        this.usersPointService.setGameVendorShortName(gameVendorShortName);

        this.auth.userLoggedStatusChanged.next(true);
    }

    getSenetSession() {
        this.showLoader();
        let post = this.http.post<any>(this.config.getSenetSession, {});
        return post;
    }
}
